<script>
import {mapState} from 'vuex';
import client from '@/utils/client';

export default {
  computed: mapState({
    vertrag: state => state.vertrag,
  }),
  data() {
    return {
      email: '',
      anrede: '',
      vorname: '',
      nachname: '',
      anreden: [
        {value: 'Herr'},
        {value: 'Frau'},
        {value: 'Divers'},
      ],
      requiredRules: [],
      emailRules: [],
      loading: false,
      confirmDialog: false,
    }
  },
  mounted() {
    if(this.$store.getters['vertrag/isMitbenutzer']) {
      this.$router.push('/dashboard');
    }
    this.anreden[0].label = this.$vuetify.lang.t('$vuetify.mitbenutzer.herr');
    this.anreden[1].label = this.$vuetify.lang.t('$vuetify.mitbenutzer.frau');
    this.anreden[2].label = this.$vuetify.lang.t('$vuetify.mitbenutzer.divers');

    this.requiredRules = [
      v => {
        if(!v) {
          return this.$vuetify.lang.t('$vuetify.mitbenutzer.eingabe_erforderlich');
        }
        return true;
      },
    ];
    this.emailRules = [
      v => {
        if(!v) {
          return this.$vuetify.lang.t('$vuetify.mitbenutzer.eingabe_erforderlich');
        }
        if(!/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(v)) {
          return this.$vuetify.lang.t('$vuetify.mitbenutzer.email_ungueltig');
        }
        return true;
      },
    ];
  },
  methods: {
    add() {
      if(this.$refs['add-form'].validate()) {
        this.loading = true;
        client.post('/vertraege/' + this.vertrag.id + '/mitbenutzer', {
          email: this.email,
          anrede: this.anrede,
          vorname: this.vorname,
          nachname: this.nachname,
        }).then(response => {
          this.$store.commit('user/updateMitbenutzerForVertrag', {id: this.vertrag.id, mitbenutzer: response.data})
          this.vertrag.mitbenutzer = response.data;
          this.loading = false;
          this.confirmDialog = false;
          this.$store.commit('snackbar/set', {
            show: true,
            color: 'success',
            message: this.$vuetify.lang.t('$vuetify.mitbenutzer.speichern_erfolgreich')
          });
        }).catch(() => {
          this.loading = false;
        });
      }
    },
    remove() {
      this.loading = true;
      client.delete('/vertraege/' + this.vertrag.id + '/mitbenutzer').then(() => {
        this.$store.commit('user/updateMitbenutzerForVertrag', {id: this.vertrag.id, mitbenutzer: null})
        this.vertrag.mitbenutzer = null;
        this.email = '';
        this.anrede = '';
        this.vorname = '';
        this.nachname = '';
        this.confirmDialog = false;
        this.loading = false;
        this.$store.commit('snackbar/set', {
          show: true,
          color: 'success',
          message: this.$vuetify.lang.t('$vuetify.mitbenutzer.entfernt')
        });
      }).catch(() => {
        this.loading = false;
      });
    }
  }
};
</script>

<template>
  <v-container fluid v-if="!$store.getters['vertrag/isMitbenutzer']">
    <v-card>
      <v-form ref="add-form" v-if="!vertrag.mitbenutzer || !vertrag.mitbenutzer.id">
        <v-card-title>
          {{ $vuetify.lang.t('$vuetify.mitbenutzer.hinzufuegen') }}
        </v-card-title>
        <v-card-text>
          <p>{{ $vuetify.lang.t('$vuetify.mitbenutzer.hinzufuegen_hinweis') }}</p>
          <v-text-field :label="$vuetify.lang.t('$vuetify.login.email')" v-model="email" required type="email" outlined dense :rules="emailRules"/>
          <v-select :label="$vuetify.lang.t('$vuetify.mitbenutzer.anrede')" v-model="anrede" :items="anreden" item-text="label" item-value="value" outlined dense/>
          <v-text-field :label="$vuetify.lang.t('$vuetify.mitbenutzer.vorname')" v-model="vorname" required outlined dense :rules="requiredRules"/>
          <v-text-field :label="$vuetify.lang.t('$vuetify.mitbenutzer.nachname')" v-model="nachname" required outlined dense :rules="requiredRules"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" depressed @click="add" :loading="loading">{{ $vuetify.lang.t('$vuetify.mitbenutzer.anlegen') }}</v-btn>
        </v-card-actions>
      </v-form>
      <div v-else>
        <v-card-title>
          {{ $vuetify.lang.t('$vuetify.mitbenutzer.verwalten') }}
        </v-card-title>
        <v-card-text>
          <p>{{ $vuetify.lang.t('$vuetify.mitbenutzer.ihr_mitbenutzer') }}</p>
          <div v-if="vertrag.mitbenutzer.anrede">{{vertrag.mitbenutzer.anrede}}</div>
          <div>{{vertrag.mitbenutzer.vorname}} {{vertrag.mitbenutzer.nachname}}</div>
          <div><a :href="'mailto:' + vertrag.mitbenutzer.email">{{vertrag.mitbenutzer.email}}</a></div>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn depressed color="secondary" :loading="loading" @click="confirmDialog = true">{{ $vuetify.lang.t('$vuetify.mitbenutzer.entfernen') }}</v-btn>
        </v-card-actions>
      </div>
    </v-card>
    <v-dialog v-model="confirmDialog" max-width="450">
      <v-card>
        <v-card-title>
          {{ $vuetify.lang.t('$vuetify.mitbenutzer.entfernen_bestaetigung') }}
        </v-card-title>
        <v-card-text>
          {{ $vuetify.lang.t('$vuetify.mitbenutzer.entfernen_hinweis', vertrag.mitbenutzer ? vertrag.mitbenutzer.email : '') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn depressed color="dark" :disabled="loading" @click="confirmDialog = false">{{ $vuetify.lang.t('$vuetify.abbrechen') }}</v-btn>
          <v-btn depressed color="primary" :loading="loading" @click="remove">{{ $vuetify.lang.t('$vuetify.ja') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
